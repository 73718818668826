import { ref } from "vue";

import * as Api from "@/api";
import { useStore } from "@/use/useStore";

const limit = ref<{ limit: number }>({ limit: -1 });

export function useScore() {
  const { currentUser } = useStore();

  const score = ref<Maybe<UserScore>>();

  async function fetchScore() {
    const { data } = await Api.fetchScore(currentUser.value!.id);
    score.value = data;
  }

  async function fetchLimit() {
    const { data } = await Api.fetchLimit(currentUser.value!.id);
    limit.value = data;
  }

  async function upateLimit(newLimit: number) {
    await Api.updateLimit(currentUser.value!.id, newLimit);
    limit.value = { limit: newLimit };

    fetchLimit();
  }

  async function fetchRefill(total: number) {
    const { data } = await Api.fetchRefill(currentUser.value!.id, total);
    return data;
    // window.location.href = data.qr;
  }

  return {
    score,
    limit,
    fetchRefill,
    fetchScore,
    upateLimit,
    fetchLimit,
  };
}
