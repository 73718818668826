import { ref } from "vue";

import * as Api from "@/api";
import { useStore } from "@/use/useStore";

export function useVoting() {
  const { currentUser } = useStore();

  async function getStat() {
    const { data } = await Api.getCurrentStat(currentUser.value!.id);
    return data;
  }

  async function getVoting() {
    const { data } = await Api.getCurrentVoting(currentUser.value!.id);
    return data;
  }

  async function setVoting(value: number) {
    const { data } = await Api.setCurrentVoting(currentUser.value!.id, value);
    return data;
  }

  return {
    getStat,
    getVoting,
    setVoting,
  };
}
